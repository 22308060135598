import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import ModalManager from "../../shared/modal";
import i18n from "../../shared/i18n/i18n";
import DateUtilities from "../../shared/utilities/date_utilities";
import Auth from "../../shared/auth";

let saving, loading, unit, callback, cancelCallback, sessions, selectedSession, modalId;

function loadSessions() {
    loading = true;
    Requester.get(Gateway.admin + "units/event/" + unit.properties.unitId + "/sessions")
        .then(function (result) {
            sessions = result.entities;
            setSelectedSession();
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function setSelectedSession() {
    selectedSession = null;
    if (sessions && unit.properties.sessions[0].id) {
        for (let i = 0; i < sessions.length; i++) {
            if (sessions[i].properties.id === unit.properties.sessions[0].id)
                selectedSession = sessions[i].properties;
        }
    }
}

function updateSessionEnrolment() {
    saving = true;
    Requester.post(Gateway.admin + "units/event/enrolments/" + unit.properties.id + "?sessionId=" + (selectedSession ? selectedSession.id : null))
        .then(function () {
            ModalManager.close(modalId);
            callback();
            Toasts.add("success", i18n.t("saved_changes"));
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        saving = false;
    });
}

const Modal_Manage_Session_Enrolment = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        callback = vnode.attrs.callback;
        cancelCallback = vnode.attrs.cancelCallback;
        loadSessions();
    },
    view: function () {
        return [
            !loading ? [
                m(".form-section", [
                    m(".text-bolder",  i18n.t("face_to_face_event")),
                    unit.properties.unitName
                ]),
                m(".form-section", [
                    m(".text-bolder", unit.properties.sessions[0].id ? i18n.t("change_session") : i18n.t("select_session")),
                    m("select", {
                        disabled: !Auth.validateRights(["enrolments_edit"]),
                        onchange: function () {
                            unit.properties.sessions[0].id = this.value;
                            setSelectedSession();
                        }
                    }, [
                        m("option", {value: null}, i18n.t("unspecified")),
                        sessions.map(function (session) {
                            return [
                                m("option", {
                                    selected: unit.properties.sessions[0].id === session.properties.id,
                                    value: session.properties.id
                                }, session.properties.name + " (" + session.properties.location + ") " + DateUtilities.getFormattedDate(session.properties.start))
                            ];
                        })
                    ])
                ]),
                selectedSession ? [
                    m(".form-section", [
                        m(".text-bolder", i18n.t("session_name")),
                        selectedSession.name
                    ]),
                    m(".form-section", [
                        m(".text-bolder", i18n.t("Status")),
                        selectedSession.isActive ? i18n.t("Active") : i18n.t("Inactive")
                    ]),
                    m(".form-section", [
                        m(".text-bolder", i18n.t("location")),
                        selectedSession.location
                    ]),
                    m(".form-section", [
                        m(".text-bolder",  i18n.t("start")),
                        DateUtilities.getFormattedDate(selectedSession.start)
                    ]),
                    m(".form-section", [
                        m(".text-bolder", i18n.t("end")),
                        DateUtilities.getFormattedDate(selectedSession.end)
                    ]),
                ] : "No session selected",
                Auth.validateRights(["enrolments_edit"],
                    [
                        m("hr"),
                        m(".form-buttons justify-between", {class: loading ? "disable-buttons" : ""}, [
                            !saving
                                ? m("button", {onclick: updateSessionEnrolment}, i18n.t("save_changes"))
                                : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")]),
                            [m("button.btn-text btn-error", {
                                onclick: function () {
                                    cancelCallback(unit);
                                }, class: saving ? "disabled" : ""
                            }, unit.properties.isCancelled ? i18n.t("restore_session_enrolment") : i18n.t("cancel_session_enrolment"))]
                        ])
                    ]
                )
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
}
export default Modal_Manage_Session_Enrolment;
