import m from "mithril";
import i18n from "../i18n/i18n";
import {UnitTypes} from "../../admin/values";

export const EnrolmentStatus = Object.freeze({
    NOT_STARTED: "not_started",
    PASSED: "passed",
    FAILED: "failed",
    ARCHIVED: "archived",
    IN_PROGRESS: "in_progress",
    CANCELLED: "cancelled",         // Used for Unit Enrolments
    UN_ARCHIVED: "un_archived",     // Not a status but used as a constant where other statuses are
})

export function getCourseEnrolmentStatus(enr, adminView, loading){
    if(!loading) {
        let courseStatusResult= adminView ? enr.properties : enr;

        if(enr) {
            if (courseStatusResult.isCompleted && courseStatusResult.isPassed) {
                return m(".label-circle label-success", i18n.t("passed"));
            } else if (courseStatusResult.isCompleted && !courseStatusResult.isPassed) {
                return m(".label-circle label-error", i18n.t("failed"));
            } else if (courseStatusResult.isStarted) {
                return m(".label-circle label-warning", i18n.t("in_progress"));
            } else {
                return m(".label-circle", i18n.t("not_started"));
            }
        }
    }
}

export function getUnitStatus(unit){

    if (unit.loading) {
        return m('.unit-status-icon loading', m('i'));
    }

    if (unit.type === UnitTypes.approval && unit.enrolment) {
        if (unit.enrolment.isPassed) {
            return m('.unit-status-icon passed', m('i.icon-check'));
        }
        else if (unit.enrolment.isViewed) {
            return m('.unit-status-icon warn', { title: i18n.t("viewed") }, m('i.icon-visible'));
        } else if (unit.enrolment.submitted) {
            return m('.unit-status-icon warn', { title: i18n.t("pending_approval") }, m('i.icon-clock'));
        }
        else if(unit.enrolment.isCompleted && !unit.enrolment.isPassed){
            return m('.unit-status-icon warn', m('i.icon-clock'));
        }
    }

    if (unit.enrolment && unit.enrolment.isCompleted) {
        if (unit.enrolment.isPassed) {
            return m('.unit-status-icon passed', m('i.icon-check'));
        } else {
            return m('.unit-status-icon failed', m('i.icon-x'));
        }
    }

    return '';
}

export const createOption = (textKey, callback, param) =>
    m("div", { onclick: () => callback(param, textKey) }, i18n.t(textKey));
