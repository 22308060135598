import SessionController from './session';

function getRights() {
    if (!SessionController.session || !SessionController.session.identity || !SessionController.session.identity.rights) {
        return null;
    }
    let rights = SessionController.session.identity.rights;
    if (!Array.isArray(rights)) {
        // this is to fix the issue where sometimes the rights is returned as an array already
        rights = rights.split(',');
    }
    return rights;
}

function hasInternalRole(rights) {
    return !!~rights.indexOf(Auth.rights["role_internal"]);
}

function validateRights(requestedRights, output, requireAll) {
    const rights = getRights();
    if (!rights) {
        return null;
    }

    const valid = requireAll
        ? hasAllRequestedRights(rights, requestedRights)
        : hasAnyRequestedRights(rights, requestedRights);

    return valid ? output || true : null;
}

function hasAllRequestedRights(rights, requestedRights) {
    for (let index = 0; index < requestedRights.length; index++) {
        const requestedRight = Auth.rights[requestedRights[index]];
        if (!~rights.indexOf(requestedRight)) {
            return false;
        }
    }
    return true;
}

function hasAnyRequestedRights(rights, requestedRights) {
    for (let index = 0; index < requestedRights.length; index++) {
        const requestedRight = Auth.rights[requestedRights[index]];
        if (~rights.indexOf(requestedRight)) {
            return true;
        }
    }
    return false;
}

function validateInternalRole(output) {
    const rights = getRights();
    if (!rights || !hasInternalRole(rights)) {
        return null;
    }

    return output || true;
}

let Auth = {
    validateInternalAccess: function (output) {
        return validateInternalRole(output);
    },

    validateRights: function (allow, output, all) {
        return validateRights(allow, output, all);
    },

    rights: {
        role_internal: "role_internal",
        role_external: "role_external",

        admin_section: "admin_section",

        users_view: "users_view",
        users_create: "users_create",
        users_edit: "users_edit",
        users_delete: "users_delete",
        users_password_reset: "users_password_reset",
        users_email_edit: "users_email_edit",
        users_organisation_remove: "users_organisation_remove",
        users_username_edit: "users_username_edit",

        courses_view: "courses_view",
        courses_create: "courses_create",
        courses_edit_content: "courses_edit_content",
        courses_edit_details: "courses_edit_details",
        courses_edit_settings: "courses_edit_settings",
        courses_edit_checklist: "courses_edit_checklist",
        courses_delete: "courses_delete",
        courses_sync: "courses_sync",
        courses_add_unit: "courses_add_unit",

        unit_scorm_view: "unit_scorm_view",
        unit_scorm_create: "unit_scorm_create",
        unit_scorm_edit: "unit_scorm_edit",
        unit_scorm_delete: "unit_scorm_delete",

        unit_event_view: "unit_event_view",
        unit_event_create: "unit_event_create",
        unit_event_edit: "unit_event_edit",
        unit_event_delete: "unit_event_delete",

        unit_form_view: "unit_form_view",
        unit_form_create: "unit_form_create",
        unit_form_edit: "unit_form_edit",
        unit_form_delete: "unit_form_delete",

        unit_certificate_view: "unit_certificate_view",
        unit_certificate_create: "unit_certificate_create",
        unit_certificate_edit: "unit_certificate_edit",
        unit_certificate_delete: "unit_certificate_delete",

        unit_document_view: "unit_document_view",
        unit_document_create: "unit_document_create",
        unit_document_edit: "unit_document_edit",
        unit_document_delete: "unit_document_delete",

        unit_action_view: "unit_action_view",
        unit_action_create: "unit_action_create",
        unit_action_edit: "unit_action_edit",
        unit_action_delete: "unit_action_delete",

        unit_approval_view: "unit_approval_view",
        unit_approval_create: "unit_approval_create",
        unit_approval_edit: "unit_approval_edit",
        unit_approval_delete: "unit_approval_delete",
        unit_approval_action: "unit_approval_action",

        unit_download_view: "unit_download_view",
        unit_download_create: "unit_download_create",
        unit_download_edit: "unit_download_edit",
        unit_download_delete: "unit_download_delete",

        unit_upload_view: "unit_upload_view",
        unit_upload_create: "unit_upload_create",
        unit_upload_edit: "unit_upload_edit",
        unit_upload_delete: "unit_upload_delete",

        enrolments_view: "enrolments_view",
        enrolments_archived_view: "enrolments_archived_view",
        enrolments_create: "enrolments_create",
        enrolments_edit: "enrolments_edit",
        enrolments_delete: "enrolments_delete",
        enrolments_session_edit: "enrolments_session_edit",
        enrolments_reset: "enrolments_reset",

        org_view: "org_view",
        org_edit: "org_edit",
        org_create: "org_create",
        org_delete: "org_delete",

        email_templates_view: "email_templates_view",

        account_add_organisation: "account_add_organisation",
        account_settings_view: "account_settings_view",
        account_single_sign_on_configuration_create: "account_single_sign_on_configuration_create",
        account_single_sign_on_configuration_update: "account_single_sign_on_configuration_update",
        account_single_sign_on_configuration_view: "account_single_sign_on_configuration_view",
        account_single_sign_on_configuration_delete: "account_single_sign_on_configuration_delete",
        account_user_remove: "account_user_remove",
        enrolments_form_unit_response_view: "enrolments_form_unit_response_view",

        unit_change_their_session_visible: "unit_change_their_session_visible",
        unit_change_their_session_edit: "unit_change_their_session_edit",
        events_view: "events_view",
    }
};

export default Auth;
