import m from "mithril";

const ArnBarWidget = {
    oncreate: (vnode) => {
        var s = document.createElement('script');
        s.type = "text/javascript";
        s.async = true;
        const userId = vnode.attrs.userId || "";
        s.src = window.ENV.ARN_WIDGET_URL + userId;
        s.onload = () => {
            if (window.arnWidget) {
                new window.arnWidget();
            }
        };
        document.head.appendChild(s);
    },
    view: () => {
        return m("");
    }
};

export default ArnBarWidget;
