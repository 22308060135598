import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import Toasts from "../../../shared/toasts";
import Auth from "../../../shared/auth";
import RouterService from "../../../shared/services/router.service";
import { changeEnrolmentStatusPrompt } from '../../../shared/utilities/confirmStatusChange_utilities';
import Component_Table_Footer from "../../../shared/components/component_table_footer";
import {createOption, EnrolmentStatus} from "../../../shared/utilities/courseEnrolmentStatus_utilities";

let loading, filter, unit, session, enrolments, selected, saving = [];
let total = 0, limit = 25, offset = 0;

function submitPagination(lim, off) {
    limit = lim;
    offset = off;
    load();
}

function load() {
    loading = true;
    selected = [];

    let params = [];
    
    params.push("sessionId=" + session.properties.id);
    params.push("limit=" + limit);
    params.push("offset=" + offset * limit);
    
    if (filter) {
        params.push("filter=" + filter);
    }
    
    Requester.get(Gateway.admin + "units/event/" + unit.properties.id + "/enrolments" + (params.length > 0 ? "?" + params.join("&") : ""))
        .then(function (result) {
            total = result.properties.totalCount;
            enrolments = result.entities;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    })
        .then(function () {
            loading = false;
        });
}

function changeSessionResult(status) {
    saving = true;
    Requester.post(Gateway.admin + "units/enrolments/result", {ids: selected, status: status, isCancelled: false, courseEnrolmentIds: extractCourseEnrolmentIds(selected)})
        .then(function () {
            setStatusForEach(selected, status);
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            saving = false;
        });
}

function extractCourseEnrolmentIds(selectedUnitIds){
    let courseEnrolmentIds= [];
    selectedUnitIds.forEach(unit => {
        let enrolment = enrolments.find(e => e.properties.id === unit);
        courseEnrolmentIds.push(enrolment.properties.courseEnrolmentLinks[0].id);
    });

    return courseEnrolmentIds;
}

function cancelSessionEnrolments() {
    saving = true;
    Requester.post(Gateway.admin + "units/event/enrolments/cancel", {ids: selected, isCancelled: true})
        .then(function () {
            setStatusForEach(selected, "cancelled");
        })
        .catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        saving = false;
    });
}

function setStatusForEach (selected, status) {

    enrolments.filter(enrolment => selected.includes(enrolment.properties.id))
        .forEach(enrolment => setStatus(enrolment, status));
}

function setStatus (enrolment, status) {
    if (status === "" || status === undefined || status === "not_started") {
        setNotStarted(enrolment);
    } else if (status === "started") {
        setIsInProgress(enrolment);
    } else if (status === "passed") {
        setIsPassed(enrolment);
    } else if (status === "failed") {
        setIsFailed(enrolment);
    } else if (status === "cancelled") {
        setIsCancelled(enrolment);
    } else {
        // Unknown status
    }
}

function setNotStarted (enrolment) {
    enrolment.properties.isStarted = false;
    
    enrolment.properties.isCancelled = false;
    enrolment.properties.isCompleted = false;
    enrolment.properties.isPassed = false;
}

function setIsInProgress (enrolment) {
    enrolment.properties.isStarted = true;

    enrolment.properties.isCancelled = false;
    enrolment.properties.isCompleted = false;
    enrolment.properties.isPassed = false;
}

function setIsPassed (enrolment) {
    enrolment.properties.isStarted = true;
    enrolment.properties.isCompleted = true;
    enrolment.properties.isPassed = true;

    enrolment.properties.isCancelled = false;
}

function setIsFailed (enrolment) {
    enrolment.properties.isStarted = true;
    enrolment.properties.isCompleted = true;
    enrolment.properties.isPassed = false;

    enrolment.properties.isCancelled = false;
}

function setIsCancelled (enrolment) {
    enrolment.properties.isStarted = true;
    enrolment.properties.isCancelled = true;

    enrolment.properties.isCompleted = false;
    enrolment.properties.isPassed = false;
}

function toggleAllSelected() {
    if (selected.length > 0)
        selected = [];
    else {
        if (enrolments) {
            for (let i = 0; i < enrolments.length; i++) {
                if(!enrolments[i].properties.isArchived) {
                    selected.push(enrolments[i].properties.id);
                }
            }
        }
    }
}

function toggleSelected(id) {
    let index = selected.indexOf(id);
    if (index === -1)
        selected.push(id);
    else
        selected.splice(index, 1);
}

function populateChangeResultOptions() {

    const optionsMap = {
        notStarted: createOption(EnrolmentStatus.NOT_STARTED, changeEnrolmentStatusPrompt, changeSessionResult),
        passed: createOption(EnrolmentStatus.PASSED, changeEnrolmentStatusPrompt, changeSessionResult),
        failed: createOption(EnrolmentStatus.FAILED, changeEnrolmentStatusPrompt, changeSessionResult),
        cancelled: createOption(EnrolmentStatus.CANCELLED, changeEnrolmentStatusPrompt, cancelSessionEnrolments)
    }

    let options = []

    if(Auth.validateRights(["enrolments_session_edit"])) {
        options = [optionsMap.notStarted, optionsMap.passed, optionsMap.failed]
    } else if(Auth.validateRights(["enrolments_reset"])) {
        options = [optionsMap.notStarted];
    }
    if(Auth.validateRights(["enrolments_edit"])) {
        options.push(optionsMap.cancelled);
    }

    return options.length
        ? m(".dropdown", {class: selected.length === 0 ? "disabled" : ""},
            m("button.with-icon pl-2", [
                i18n.t("change_result"),
                m("i.icon-back rotate-270 ml-2")
            ]),
            m(".dropdown-list", [options])
        )
        : null;
}

const Component_Unit_Event_Session = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        session = vnode.attrs.session;
        filter = "all_active";
        load();
    },
    onremove: function () {
        session.selected = false;
    },
    view: function (vnode) {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        m("button.with-icon btn-cta", {
                            onclick: function () {
                                vnode.attrs.callback();
                            }
                        }, [m("i.icon-arrow-back"), i18n.t("back_to_all_sessions")]),
                        populateChangeResultOptions()
                    ]),
                    m(".toolbar-section", [
                        m(".dropdown",
                            m("button.with-icon pl-2", [
                                filter === "all_active" ? i18n.t("all_attendees") :
                                filter === "not_started" ? i18n.t("not_started") : 
                                    filter === "passed" ? i18n.t("passed") :
                                        filter === "archived" ? i18n.t("archived") :
                                            filter === "failed" ? i18n.t("failed") :
                                            i18n.t("all_attendees"),
                                
                                m("i.icon-back rotate-270 ml-2")
                            ]),
                            m(".dropdown-list", [
                                m("div", {
                                    class: !filter ? "active" : "", onclick: function () {
                                        filter = "all_active";
                                        load();
                                    }
                                }, i18n.t("all_attendees")),
                                m("div", {
                                    class: filter === "not_started" ? "active" : "", onclick: function () {
                                        filter = "not_started";
                                        load();
                                    }
                                }, i18n.t("not_started")),
                                m("div", {
                                    class: filter === "passed" ? "active" : "", onclick: function () {
                                        filter = "passed";
                                        load();
                                    }
                                }, i18n.t("passed")),
                                m("div", {
                                    class: filter === "archived" ? "active" : "", onclick: function () {
                                        filter = "archived";
                                        load();
                                    }
                                }, i18n.t("archived")),
                                m("div", {
                                    class: filter === "failed" ? "active" : "", onclick: function () {
                                        filter = "failed";
                                        load();
                                    }
                                }, i18n.t("failed"))
                            ])
                        )
                    ])
                ]),
                !loading ? [
                    enrolments ? [
                        enrolments.length > 0 ? [
                            m(".table-wrapper flex-auto", [
                                m("table.full-width", [
                                    m("thead",
                                        m("tr", [
                                            m("th", m(".toolbar-checkbox", {onclick: toggleAllSelected}, m("i", {class: selected.length > 0 ? "icon-checkbox-checked" : "icon-checkbox-empty"}))),
                                            m("th", i18n.t("attendee_name")),
                                            m("th", i18n.t("result")),
                                        ])
                                    ),
                                    m("tbody", enrolments.map(function (enr) {
                                        return m("tr", [
                                            m("td.list-checkbox c-hand pl-1 pr-1", {
                                                class: enr.properties.isArchived ? "disabled" : "",
                                                onclick: function () {
                                                    toggleSelected(enr.properties.id);
                                                }, style: "width: 18px;"
                                            }, m("i", {class: selected.indexOf(enr.properties.id) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty"})),
                                            m("td.text-bolder", m(m.route.Link, {
                                                href: RouterService.getAdminUserUrl(enr.properties.userId),
                                                tabindex: "-1"
                                            }, enr.properties.userFirstName ? enr.properties.userFirstName + " " + enr.properties.userLastName : i18n.t("invited_user"))),
                                            m("td", m(m.route.Link, {
                                                href: RouterService.getAdminUserUrl(enr.properties.userId),
                                                tabindex: "-1"
                                            }, enr.properties.isArchived ? m(".label-circle label-error", i18n.t("archived")) : enr.properties.isCancelled ? m(".label-circle label-error", i18n.t("cancelled")) : enr.properties.isCompleted && enr.properties.isPassed ? m(".label-circle label-success", i18n.t("passed")) : enr.properties.isCompleted && !enr.properties.isPassed ? m(".label-circle label-error", i18n.t("failed")) : enr.properties.isStarted ? m(".label-circle label-warning", i18n.t("in_progress")) : m(".label-circle", i18n.t("not_started"))))
                                        ])
                                    }))
                                ])
                            ])
                        ] : m(".text-gray padding", i18n.t("no_attendees")),
                        m(Component_Table_Footer, {
                            callback: submitPagination,
                            total: total,
                            limit: limit,
                            offset: offset,
                            loading: loading,
                            item: "items"
                        })
                    ] : m(".text-gray padding", i18n.t("unable_to_load_attendees"))
                ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
            ])
        ];
    }
};
export default Component_Unit_Event_Session;
